export type DealershipNotification = {
  _id: string;
  deleted: boolean;
  createdAt?: Date | string;
  data: DealershipNotificationData;
};

export enum DealershipNotificationType {
  Note = "note",
  Stipulation = "stipulation",
  ScanLicense = "scan_license",
  Chat = "chat",
  Application = "application",
  TitleIssue = "title_issue",
  DealershipTicket = "dealership_ticket",
}

export interface StipulationInfo {
  type: DealershipNotificationType.Stipulation;
  body: {
    description: string;
    customersNames: string;
    managerNames: string;
  };
}
export interface NoteInfo {
  type: DealershipNotificationType.Note;
  body: {
    description: string;
    customersNames: string;
    managerNames: string;
  };
}
export interface ApplicationInfo {
  type: DealershipNotificationType.Application;
  body: {
    description: string;
    customersNames: string;
  };
}
export interface TitleIssueNoteInfo {
  type: DealershipNotificationType.TitleIssue;
  body: {
    description: string;
    managerNames?: string; //might not always be manager
  };
}
export interface DealershipTicketNoteInfo {
  type: DealershipNotificationType.DealershipTicket;
  body: {
    description: string;
    managerNames?: string; //might not always be manager
  };
}
export interface OtherInfo {
  type:
    | DealershipNotificationType.Chat
    | DealershipNotificationType.ScanLicense
    | DealershipNotificationType.TitleIssue;
  body: {
    description: string;
  };
}

export interface DeleteAllDealershipNotificationsRequest {
  requestId: string;
  userId: string;
}
export interface DefaultInfo {
  level: number;
  referenceId: string;
  title: string;
  userEmail: string;
  seenAt?: string;
}
export type Info = DefaultInfo &
  (
    | StipulationInfo
    | NoteInfo
    | ApplicationInfo
    | OtherInfo
    | TitleIssueNoteInfo
    | DealershipTicketNoteInfo
  );

export interface DealershipNotificationData {
  info: Info;
  userId: string;
}
