import BackButton from "@Components/BackButton";
import Grid from "@Components/Grid";
import Stepper from "@Components/Stepper";
import { getApplication } from "@Http/applications";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Applicant from "./Applicants";
import ChangeRequests from "./ChangeRequests";
import Collateral from "./Collateral";
import UploadStipulations from "./Documents/Stipulations";
import UploadDocuments from "./Documents/UploadDocuments";
import FinancialInfo from "./FinancialInfo";
import TradeIn from "./TradeIn";

import { DealStatus } from "@Types/deal";
import styles from "./Application.module.scss";
import ApplicationPdf from "./ApplicationPdf";
import DealerNotes from "./DealerNotes";
import UploadDocumentsTiny from "./Documents/UploadDocuments/UploadDocumentsTiny";
import UploadFundedDocuments from "./Documents/UploadFundedDocuments";
import UploadFundedDocumentsTiny from "./Documents/UploadFundedDocuments/UploadFundedDocumentsTiny";
import StatusChange from "./StatusChange/StatusChange";

const Deal = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: deal,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["deal", id],
    queryFn: getApplication(id ?? ""),
  });

  if (isLoading) return <>Loading...</>;
  if (error) return <>{`${(error as { message: string })?.message}`}</>;
  return deal ? (
    <Grid container xs={12} spacing={1} className={styles.container}>
      <Grid xs={1.5}>
        <BackButton />
      </Grid>
      <Grid xs={12}>
        <div className={styles.applicationProgress}>
          <h2 className={styles.title}>Application progress</h2>
          {[
            DealStatus.Countered,
            DealStatus.Approved,
            DealStatus.SentToDealer,
            DealStatus.Dead,
          ].includes(deal?.data?.info?.status) &&
            !deal?.data?.info?.statusReasons?.dealership?.marked && (
              <StatusChange deal={deal} requestedStatus={DealStatus.Dead} />
            )}
        </div>
        <div style={{ marginBottom: "110px" }}>
          <Stepper deal={deal} />
        </div>
      </Grid>
      {deal?.data?.info?.status === DealStatus.SentToDealer ||
      deal?.data?.info?.status === DealStatus.SubmittedForFunding ||
      deal?.data?.info?.status === DealStatus.FundingHeld ||
      deal?.data?.info?.status === DealStatus.Funded ? (
        Array.isArray(deal?.data?.stipulations) &&
        deal?.data?.stipulations?.length > 0 ? (
          <>
            <Grid xs={4}>
              <UploadFundedDocumentsTiny deal={deal} />
            </Grid>
            <Grid xs={4}>
              <UploadStipulations deal={deal} />
            </Grid>
            <Grid xs={4}>
              <DealerNotes deal={deal} />
            </Grid>
          </>
        ) : (
          <>
            <Grid xs={8}>
              <UploadFundedDocuments deal={deal} />
            </Grid>
            <Grid xs={4}>
              <DealerNotes deal={deal} />
            </Grid>
          </>
        )
      ) : deal?.data?.info?.status !== DealStatus.Lead &&
        Array.isArray(deal?.data?.stipulations) &&
        deal?.data?.stipulations?.length > 0 ? (
        <>
          <Grid xs={4}>
            <UploadDocumentsTiny deal={deal} />
          </Grid>
          <Grid xs={4}>
            <UploadStipulations deal={deal} />
          </Grid>
          <Grid xs={4}>
            <DealerNotes deal={deal} />
          </Grid>
        </>
      ) : (
        <>
          <Grid xs={8}>
            <UploadDocuments deal={deal} />
          </Grid>
          <Grid xs={4}>
            <DealerNotes deal={deal} />
          </Grid>
        </>
      )}
      <Grid xs={6}>
        <ChangeRequests deal={deal} />
      </Grid>
      <Grid xs={6}>
        <ApplicationPdf deal={deal} />
      </Grid>
      <Grid xs={8}>
        <Applicant type="applicant" deal={deal} />
      </Grid>
      <Grid xs={4}>
        <Applicant type="coApplicant" deal={deal} />
      </Grid>
      <Grid xs={8}>
        <Collateral
          collateral={deal?.data?.info?.vehicle}
          type={deal?.data?.info?.type}
        />
      </Grid>
      <Grid xs={4}>
        <TradeIn tradeIn={deal?.data?.info?.price?.trade?.[0]} />
      </Grid>

      <Grid xs={12}>
        <FinancialInfo deal={deal} />
      </Grid>
    </Grid>
  ) : null;
};

export default Deal;
