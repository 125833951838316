import { UseMutateFunction } from "@tanstack/react-query";
import LoadingFullScreen from "@Components/Loading/LoadingFullScreen";
import IconButton from "@Components/IconButton";
import { AiOutlineDelete } from "react-icons/ai";
import { Settings as SettingsType } from "@Types/settings";
import { MutationError } from "@Types/index";
import styles from "./Settings.module.scss";

function Actions({ adfEmailIndex }: { adfEmailIndex: number }) {
  return (
    <IconButton id={`delete-setting-${adfEmailIndex}`} className={"blueCircle"}>
      <AiOutlineDelete size={18} />
    </IconButton>
  );
}

interface SettingsTableProps {
  settings?: SettingsType;
  isLoading?: boolean;
  updateSettings: UseMutateFunction<
    SettingsType,
    MutationError,
    SettingsType | Omit<SettingsType, "_id">,
    unknown
  >;
}

const SettingsTable = ({
  settings,
  isLoading,
  updateSettings,
}: SettingsTableProps) => {
  const handleDelete = (adfEmailIndex: number) => {
    if (settings?.data?.info?.adfEmails) {
      updateSettings({
        ...settings,
        data: {
          ...settings.data,
          info: {
            ...settings?.data.info,
            adfEmails: settings.data.info.adfEmails.filter(
              (_, index) => index !== adfEmailIndex
            ),
          },
        },
      });
    }
  };

  const columns = [{ label: "Email" }, { label: "Actions" }];
  const entries = settings?.data?.info?.adfEmails.map((email, index) => [
    email,
    <div key={index} onClick={() => handleDelete(index)}>
      <Actions adfEmailIndex={index} />
    </div>,
  ]);

  return (
    <div className={styles.tableContainer}>
      <h2 className={styles.tableTitle}>ADF Emails</h2>
      <table
        cellSpacing={0}
        cellPadding={0}
        border={0}
        className={styles.table}
      >
        <thead className={styles.tableHead}>
          <tr>
            {columns.map((column, index) => (
              <th key={index} className={styles.tableColumnHeader}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {entries?.length ? (
            entries.map((entry, rowIndex) => {
              return (
                <tr key={rowIndex} className={styles.tableRow}>
                  {entry.map((value, columnIndex) => (
                    <td key={columnIndex} className={styles.tableColumn}>
                      <div
                        className={
                          typeof value === "object"
                            ? styles.tableColumnActions
                            : styles.tableColumnContainer
                        }
                      >
                        {value}
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })
          ) : isLoading ? (
            <tr className={styles.notFound}>
              <td colSpan={columns.length ?? 1}>
                <LoadingFullScreen />
              </td>
            </tr>
          ) : (
            <tr className={styles.notFound}>
              <td colSpan={columns.length ?? 1}>{"No entries found!"}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SettingsTable;
