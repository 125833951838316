import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import { DialogActions } from "@Components/Dialog/DialogActions";
import { DialogContent } from "@Components/Dialog/DialogContent";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useStickyState } from "@Hooks/useStickyState";
import { useState } from "react";
import { MdOutlineNewReleases } from "react-icons/md";
import styles from "./WhatsNew.module.scss";

const WhatsNew = () => {
  const user = useCurrentUser();
  if (user && user.data.info.userType !== "owner") {
    return <></>;
  }

  const [isSeenRelease, setIsSeenRelease] = useStickyState(
    false,
    // change this name when releasing something new.
    "release-08/23/2024"
  );

  const [show, setShow] = useState(false);
  return (
    <>
      <button
        className={styles.button}
        onClick={() => {
          setShow(!show);
        }}
      >
        <span>
          <MdOutlineNewReleases />
        </span>

        {!isSeenRelease ? (
          <div style={{ paddingLeft: 10 }}>Click here to see whats new </div>
        ) : (
          ""
        )}
      </button>
      {show ? (
        <Dialog
          titleColor="blue"
          containerStyle={{
            top: "30px",
            transform: "unset",
            left: 0,
          }}
          id="create-change-request"
          open={show}
          title="Whats new?"
          closeIcon
          size="lg"
          closeFn={() => {
            setIsSeenRelease(true);
            setShow(false);
          }}
        >
          <DialogContent>
            <div>
              <h3>Release Announcement 23/08/2024: Ticket System!</h3>
              <p>
                We are excited to introduce our new Ticket System, designed to
                streamline issue tracking and support management. This feature
                enables users to efficiently report issues and monitor their
                progress. Below are the key functionalities:
              </p>

              <h2>Key Features:</h2>
              <ul>
                <li>
                  <strong>Submit a New Issue:</strong>
                  <ul>
                    <li>
                      Users can submit new issues directly through the system.
                    </li>
                    <li>
                      A detailed description field is provided to accurately
                      describe the issue.
                    </li>
                    <li>
                      Users have the option to attach a screenshot to provide
                      visual context.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Manage Existing Tickets:</strong>
                  <ul>
                    <li>View a comprehensive list of all submitted tickets.</li>
                    <li>Check the status of each issue in real-time.</li>
                    <li>
                      Send additional notes or updates to the support team
                      directly from the ticket management interface.
                    </li>
                  </ul>
                </li>
              </ul>

              <p>
                <b>
                  You can access the ticket system from the help icon on the top
                  of the screen, near the notifications icon.
                </b>
              </p>

              <p>
                <b>
                  Please use persmissions and roles from the menu on the left to
                  enable this feature to other people from your company.
                </b>
              </p>

              <p>
                We believe this new ticket system will improve communication
                between you and the support team and provide you with faster
                issue resolution with transparency in tracking the progress of
                reported issues. Thank you for your continued support and
                feedback, which drive our ongoing efforts to improve our
                application.
              </p>

              <p>Best regards,</p>
              <p>The Web Finance Direct Team</p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="blue"
              onClick={() => {
                setShow(false);
                setIsSeenRelease(true);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
export default WhatsNew;
