import Grid from "@mui/system/Unstable_Grid/Grid";
import { useRef, useState } from "react";

import Button from "@Components/Button";
import Dropzone from "@Components/Dropzone";

import Dialog from "@Components/Dialog";
import { DialogContent } from "@Components/Dialog/DialogContent";
import useCurrentUser from "@Hooks/useCurrentUser";
import { parseApplicationFile } from "@Http/file_applications";
import { ApplicationParserType } from "@Types/dealerships";
import { AxiosProgressEvent } from "axios";
import { IoCloudUpload, IoReloadCircleOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import styles from "./ApplicationDropzone.module.scss";

interface DocumentsDropzoneProps {
  uploadCallback: (x: object) => void;
  applicationId: string;
}

const ApplicationDropzone = ({
  uploadCallback,
  applicationId,
}: DocumentsDropzoneProps) => {
  const user = useCurrentUser();

  const applicationParser =
    user?.data?.dealership?.data?.info?.applicationParserType;

  const inputFile = useRef<HTMLInputElement | null>(null);
  const dropzoneRef = useRef(null);
  const [file, setFile] = useState<File | undefined>(undefined);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files?.length) {
      setFile(e.target.files[0]);
      return handleCreate(e.target.files[0]);
    }
  };
  const [loading, setLoading] = useState<boolean>(false);
  const progressChange = (x: AxiosProgressEvent) => {
    Math.round((x.progress || 0) * 100);
  };

  const handleCreate = async (file: File) => {
    if (file) {
      try {
        setLoading(true);
        const response = await parseApplicationFile(
          file,
          applicationId,
          progressChange
        );
        uploadCallback(response);
        setLoading(false);
        setFile(undefined);
        toast.success(
          "Information is prefilled successfully please continue with the form!",
          {
            position: "top-center",
          }
        );
      } catch (error: unknown) {
        setLoading(false);
        setFile(undefined);
        toast.error(
          "The system was not able to find information in this document!",
          {
            position: "top-center",
          }
        );
      }
    }
  };
  const [learnModal, setLearnModal] = useState(false);
  return (
    <Grid ref={dropzoneRef} className={`${styles.dropZone} ${styles.dashed}`}>
      {loading ? (
        <IoReloadCircleOutline
          className={"spin"}
          fontSize={80}
          style={{ color: "#6b97f6" }}
        />
      ) : (
        <IoCloudUpload fontSize={80} style={{ color: "#6b97f6" }} />
      )}

      <div
        className={styles.dropZoneContainer}
        id={"application-dropzone-container"}
      >
        <Dropzone createDocument={handleCreate} containerRef={dropzoneRef} />

        {file ? (
          <p className={styles.info}>Processing: {file.name ?? ""}</p>
        ) : (
          <p className={styles.info}>Drag file to upload or click</p>
        )}

        <button
          className={styles.learnMoreButton}
          onClick={() => {
            setLearnModal(true);
          }}
        >
          To learn how to generate application in your DMS click here!
        </button>

        <Button
          onClick={() => inputFile?.current?.click()}
          className={styles.button}
          variant="blue"
        >
          Upload
          <input
            ref={inputFile}
            type="file"
            id={"application-regular-upload-document-input"}
            hidden
            onClick={(e) => {
              (e.target as EventTarget & { value: null }).value = null;
            }}
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </Button>
      </div>
      <Dialog
        id={"learnDialog"}
        open={learnModal}
        title={"Supported document types"}
        closeIcon
        titleColor="blue"
        size="lg"
        closeFn={() => {
          setLearnModal(false);
        }}
      >
        <DialogContent>{getDescriptionByType(applicationParser)}</DialogContent>
      </Dialog>
    </Grid>
  );
};

const getDescriptionByType = (type: ApplicationParserType) => {
  switch (type) {
    case "frazer":
      return (
        <Grid container className={styles.supportedDocContainer}>
          <Grid xs={6}>
            <h3>Frazer Credit Application</h3>
            <img
              src="/example_1.png"
              alt=""
              className={styles.documentPreview}
            />
          </Grid>
          <Grid xs={6}>
            <h3>Software</h3>
            <span>Frazer</span>
            <h3>How to generate the document</h3>
            <div className={styles.howToStep}>1.Open lead</div>
            <div className={styles.howToStep}>2.Click print button</div>
            <div className={styles.howToStep}>3.Download the document</div>
            <div className={styles.howToStep}>4.Upload the document</div>
            <div className={styles.howToStep}>
              5.Upload the document to the WFD dealer portal
            </div>
          </Grid>
        </Grid>
      );
    case "reynolds":
      return (
        <Grid container className={styles.supportedDocContainer}>
          <Grid xs={6}>
            <h3>Reynolds& Reynolds Credit Application</h3>
            <img
              src="/example_2.png"
              alt=""
              className={styles.documentPreview}
            />
          </Grid>
          <Grid xs={6}>
            <h3>Software</h3>
            <span>Reynolds & Reynolds</span>
            <h3>How to generate the document</h3>
            <div className={styles.howToStep}>1.Open lead</div>
            <div className={styles.howToStep}>2.Click print button</div>
            <div className={styles.howToStep}>3.Download the document</div>
            <div className={styles.howToStep}>4.Upload the document</div>
            <div className={styles.howToStep}>
              5.Upload the document to the WFD dealer portal
            </div>
          </Grid>
        </Grid>
      );
    default:
      return (
        <Grid container className={styles.supportedDocContainer}>
          <Grid xs={6}>
            <h3>Missing information</h3>
          </Grid>
          <Grid xs={6}></Grid>
        </Grid>
      );
  }
};
export default ApplicationDropzone;
