import { auth } from "@Firebase/firebase";
import axios, { AxiosProgressEvent } from "axios";
const toBase64 = (file: File): Promise<string | null | ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const parseApplicationFile = async (
  file: File,
  applicationId: string,
  onUploadProgress: (x: AxiosProgressEvent) => void
) => {
  const token = await auth.currentUser?.getIdToken();
  const base64File = (await toBase64(file)) as string;
  const response = await axios.post(
    `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/parse_dealership_application`,
    {
      file: base64File.replace("data:application/pdf;base64,", ""),
      name: file.name,
      type: file.type,
      applicationId: applicationId,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      onUploadProgress: onUploadProgress,
    }
  );
  return response.data.result;
};
