import { useState } from "react";
import Paper from "@Components/Paper";
import Button from "@Components/Button";
import TextField from "@Components/Inputs/TextField";
import Grid from "@mui/system/Unstable_Grid";
import { AiOutlineMail } from "react-icons/ai";
import { Settings as SettingsType } from "@Types/settings";
import styles from "./Settings.module.scss";
import { UseMutateFunction } from "@tanstack/react-query";
import { MutationError } from "@Types/index";

interface SettingsFormProps {
  settings?: SettingsType;
  updateSettings: UseMutateFunction<
    SettingsType,
    MutationError,
    SettingsType | Omit<SettingsType, "_id">,
    unknown
  >;
}
const SettingsForm = ({ settings, updateSettings }: SettingsFormProps) => {
  const [email, setEmail] = useState("");

  return (
    <form
      data-testid={"settings-form"}
      id={"settings-form"}
      className={styles.settingsForm}
      onSubmit={(e) => {
        e.preventDefault();
        if (email) {
          updateSettings({
            ...(settings ?? {}),
            data: {
              ...(settings?.data ?? {}),
              info: {
                ...(settings?.data.info ?? {}),
                adfEmails: [...(settings?.data?.info?.adfEmails ?? []), email],
              },
            },
          });
          setEmail("");
        }
      }}
    >
      <Paper title="Add ADF email" outerClassName={styles.formPaper}>
        <Grid container spacing={5} xs={12}>
          <Grid md={9} sm={12}>
            <TextField
              value={email}
              id={"adf-email-input"}
              type="email"
              startIcon={<AiOutlineMail />}
              inputStyle={{ borderRadius: "5px" }}
              iconStyle={{ color: "#373739" }}
              placeholder="New Email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid md={3} sm={12} className={styles.formButton}>
            <Button
              form={"settings-form"}
              id={"save-settings-button"}
              variant="gradient"
              type="submit"
            >
              Add email
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};
export default SettingsForm;
